import { GoProjectSymlink } from "react-icons/go";
import { MdOutlineCategory } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { RiListIndefinite } from "react-icons/ri";
import { FaCodeBranch } from "react-icons/fa";
import { FaRocketchat } from "react-icons/fa";
import { FaRegNewspaper } from "react-icons/fa";

export const adminMenus = [
  { title: "Add New project", to: "/project/add", icon: <GoProjectSymlink /> },
  {
    title: "Add Project Category",
    to: "/project/category/add",
    icon: <MdOutlineCategory />,
  },
  { title: "Add New User", to: "/user/add", icon: <FaRegUser /> },
  { title: "Add New Listing", to: "/listing/add", icon: <RiListIndefinite /> },
  { title: "Add New Branch", to: "/branch/add", icon: <FaCodeBranch /> },
  { title: "Chats", to: "/admin/chats", icon: <FaRocketchat /> },
  { title: "Add News", to: "/news/add", icon: <FaRegNewspaper /> },
];
