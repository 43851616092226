import React, { useEffect, useRef, useState } from "react";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import apis from "../../utils/apis";
import "./adminChats.css";
import { TextField, InputAdornment } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const AdminChats = () => {
  const chatRef = useRef(null);
  const { dispatch, socket, navigate } = useProvideGeneralHooks();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [adminMessage, setAdminMessage] = useState("");
  const [receiver, setReceiver] = useState("");

  const handleChange = (event) => {
    setAdminMessage(event.target.value);
  };

  const sendMessageHandler = () => {
    if (adminMessage.trim().length === 0) {
      return;
    }
    if (socket) {
      socket.emit("message", {
        data: adminMessage,
        receiver: receiver?.user,
        senderId: apis().AdminId,
      });
    }
  };

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight, // Scroll to the bottom of the content
        behavior: "smooth", // Smooth scroll transition
      });
    }
  };

  useEffect(() => {
    if (socket) {
      socket.emit("register", { userId: apis().AdminId });
      // Admin visit event to fetch users
      socket.emit("adminVisit");
      socket.on("users", (usersList) => {
        const localUser = localStorage.getItem("userId");
        const formattedUsers = usersList?.list?.filter(
          (item) => item?.user !== localUser
        );

        setUsers(formattedUsers);
      });

      // Handle user live status changes
      socket.on("onUserLive", (data) => {
        const { userId, user } = data;

        const isLocalUser = localStorage.getItem("userId");
        if (userId === isLocalUser) {
          return;
        }

        // Update the users list: move the live user to the top
        setUsers((prevUsers) => {
          const updatedUsers = prevUsers.filter((item) => item._id !== userId);
          updatedUsers.unshift(user); // Adds the user to the top of the array
          return updatedUsers;
        });
        setReceiver(user);
      });

      // Listen for single conversation
      socket.on("getSingleConversion", (data) => {
        if (data?.findedConversion?.message) {
          setMessages(data?.findedConversion?.message);
        }
      });

      socket.on("message", (data) => {
        setMessages((prevMessage) => [...prevMessage, data?.savedMessage]);
      });

      socket.on("sentMessage", (data) => {
        setMessages((prevMessage) => [...prevMessage, data?.savedMessage]);
        setAdminMessage("");
      });
    }

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  const openConversation = (user) => {
    setReceiver(user);
    if (socket) {
      socket.emit("singleConversion", {
        sender: "126005",
        receiver: user?.user,
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const enterHanler = (event) => {
    if (event.key === "Enter") {
      sendMessageHandler();
    }
  };

  return (
    <div className="admin_chats_main">
      <div className="container-fluid chats_container">
        <div className="row">
          <div className="col-md-4">
            <div className="admin_chats_users">
              {users
                ?.sort((a, b) => (b.isLive ? 1 : 0) - (a.isLive ? 1 : 0))
                .map((item, index) => (
                  <div
                    key={index}
                    onClick={() => openConversation(item)}
                    className="admin_chats_user"
                  >
                    <div className="admin_chats_user_detail">
                      <div className="admin_chats_user_id">
                        <span>User ID</span>
                        <span>{item?.user?.substring(0, 16)}</span>
                      </div>
                      <div className="admin_chats_user_id">
                        <span>Registred At</span>
                        <span>
                          {new Date(item?.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    <span name={item?.isLive ? "online" : "offline"}>
                      {item?.isLive ? "Live" : "Offline"}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="col-md-8">
            <div className="admin_chats_messages_container">
              <div className="admin_chats_message_header">
                <span>User ID: {receiver?.user}</span>
                <span name={receiver?.isLive ? "online" : "offline"}>
                  {receiver?.isLive ? "Live" : "Offline"}
                </span>
              </div>
              <div ref={chatRef} className="admin_chats_chats">
                {messages?.map((item, index) => {
                  const isAdmin = item?.from === "126005";

                  return (
                    <div className={`${isAdmin && "float_right"}`} key={index}>
                      <div className="admin_chats_single_message">
                        <span>{item?.message}</span>
                        <span>
                          {new Date(item?.createdAt).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="admin_chats_sender">
                <TextField
                  onKeyDown={enterHanler}
                  onChange={handleChange}
                  value={adminMessage}
                  placeholder="type here..."
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <SendIcon
                            onClick={sendMessageHandler}
                            sx={{ cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    },
                  }}
                  name="message"
                  fullWidth
                  type="text"
                  size="small"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminChats;
