import React, { useEffect, useState } from "react";
import { buildingImage } from "../../utils/images";
import SingleListing from "./SingleListing";
import "./listingList.css";
import ProjectCategorySlider from "../projects/ProjectCategorySlider";
import { SwiperSlide } from "swiper/react";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import getWidth from "../../utils/getWidth";
const ListingList = ({ title, filter }) => {
  const { dispatch, navigate } = useProvideGeneralHooks();
  const [listing, setListing] = useState([]);

  useEffect(() => {
    const getListListing = async () => {
      const data = {
        url: apis().getListings,
        method: "POST",
        body: { filter: filter },
      };
      const result = await dispatch(httpActions(data));
      if (result?.status) {
        setListing(result?.list);
      }
    };
    getListListing();
  }, [filter]);

  return (
    <div className="listing_main">
      <span className="section_title">{title}</span>
      <div className="listing_list">
        <ProjectCategorySlider show={getWidth() ? 1 : 4}>
          {listing.map((item, index) => (
            <SwiperSlide>
              <SingleListing key={index} item={item} />
            </SwiperSlide>
          ))}
        </ProjectCategorySlider>
      </div>
    </div>
  );
};

export default ListingList;
