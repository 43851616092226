import React from "react";
import "./addReview.css";
import { Button, TextField, Rating, TextareaAutosize } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import httpActions from "../../utils/httpAction";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import apis from "../../utils/apis";
import toast from "react-hot-toast";
const AddReview = ({ listing, setClose }) => {
  const { dispatch, navigate } = useProvideGeneralHooks();

  const initialState = {
    name: "",
    review: "",
    rating: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Name is required"),
    review: Yup.string().trim().required("Review is required"),
    rating: Yup.string(),
  });

  const submitHandler = async (values) => {
    const data = {
      url: apis().addReview + "?listing=" + listing,
      method: "POST",
      body: { data: values },
    };
    const result = await dispatch(httpActions(data));
    if (result?.status) {
      toast.success(result?.message);
      setClose();
    }
  };

  return (
    <div className="add_review_main">
      <Formik
        initialValues={initialState}
        onSubmit={submitHandler}
        validationSchema={validationSchema}
      >
        {({
          handleBlur,
          handleChange,
          touched,
          values,
          setFieldValue,
          errors,
        }) => (
          <Form>
            <div className="row g-3">
              <div className="col-md-12">
                <TextField
                  type="text"
                  fullWidth
                  value={values.name}
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  name="name"
                  label="Your name"
                />
              </div>
              <div className="col-12">
                <TextareaAutosize
                  maxRows={3}
                  minRows={3}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderColor:
                      touched.review && Boolean(errors.review) && "red",
                  }} // Ensure full width
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.review}
                  name="review"
                  placeholder="Write your review"
                />
                {/* Show error message below the textarea if validation fails */}
                {touched.review && errors.review && (
                  <div style={{ fontSize: "14px" }} className="error_message">
                    {errors.review}
                  </div>
                )}
              </div>
              <div className="col-12">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <span>Rating</span>
                  <Rating
                    size="small"
                    value={values.rating || 0}
                    onChange={(event, newValue) =>
                      setFieldValue("rating", newValue)
                    }
                  />
                </div>
              </div>
              <div className="col-12">
                <Button variant="contained" type="submit">
                  Add Review
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddReview;
