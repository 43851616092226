import React, { useEffect, useRef, useState } from "react";
import "./chatBox.css";
import ChatIcon from "@mui/icons-material/Chat";
import { Button, TextField, Fab, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import apis from "../../utils/apis";
const ChatBox = () => {
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const { socket } = useProvideGeneralHooks();
  const [messages, setMessages] = useState([]);
  const chatRef = useRef();
  const messagRef = useRef(null);

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight + 400, // Scroll to the bottom of the content
        behavior: "smooth", // Smooth scroll transition
      });
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("sentMessage", (data) => {
        setMessages((prevMessage) => [...prevMessage, data?.savedMessage]);
        setMessage("");
        scrollToBottom();
      });

      socket.on("message", (data) => {
        setMessages((prevMessage) => [...prevMessage, data?.savedMessage]);
        setShow(true);
        scrollToBottom();
      });

      socket.on("getSingleConversion", (data) => {
        if (data?.findedConversion?.message) {
          setMessages(data?.findedConversion?.message);
          scrollToBottom();
        }
      });
    }
  }, [socket]);

  const openBox = () => {
    if (messagRef.current) {
      messagRef.current.focus(); // Focus the input
    }
    setShow(true);

    if (socket) {
      socket.emit("singleConversion", {
        sender: localStorage.getItem("userId"),
        receiver: "126005",
      });
    }
  };

  const closeBox = () => {
    setShow(false);
  };

  const sendMessageHandler = () => {
    if (message.trim().length === 0) {
      return;
    }
    if (socket) {
      socket.emit("message", {
        data: message,
        receiver: apis().AdminId,
        senderId: localStorage.getItem("userId"),
      });
      setMessage("");
      scrollToBottom();
    }
  };

  const onenterPress = (event) => {
    if (event.key === "Enter") {
      sendMessageHandler();
    }
  };

  const handleChnage = (event) => {
    setMessage(event.target.value);
  };

  return (
    <div className="chatbox_main">
      {!show && (
        <Fab onClick={openBox} color="primary">
          <ChatIcon />
        </Fab>
      )}
      {show && (
        <div className="chat_box_container">
          <div className="chat_box_header">
            <Fab onClick={closeBox} color="secondary" size="small">
              <CloseIcon />
            </Fab>
            <span>Hafiz Brothers</span>
          </div>
          <div ref={chatRef} className="chat_chats">
            {messages?.map((item, index) => {
              const isAdmin = item?.from === "126005";

              return (
                <div className={`${!isAdmin && "float_right"}`} key={index}>
                  <div className="admin_chats_single_message">
                    <span>{item?.message}</span>
                    <span>{new Date(item?.createdAt).toLocaleString()}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="chat_box_sender">
            <TextField
              ref={messagRef}
              onKeyDown={onenterPress}
              onChange={handleChnage}
              value={message}
              placeholder="type here..."
              size="small"
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      {window.innerWidth > 756 && (
                        <SendIcon
                          sx={{ cursor: "pointer" }}
                          onClick={sendMessageHandler}
                        />
                      )}
                    </InputAdornment>
                  ),
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
