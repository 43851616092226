import React from "react";
import "./singleNews.css";
const SingleNews = ({ item }) => {
  console.log(item);
  return (
    <div>
      <div className="single_news_main">
        <div className="row g-3">
          <div className="col-md-10 px-3">
            <p>{item?.data?.title}</p>
          </div>
          <div className="col-md-2 px-3">
            <span style={{ color: "gray", fontSize: "14px" }}>
              <strong className="orange">Posted: </strong>
              {new Date(item?.createdAt).toDateString()}
            </span>
          </div>
          <div className="col-md-6">
            <div className="news_image_container">
              <img src={item?.data?.image} />
            </div>
          </div>
          <div className="col-md-6">
            <div dangerouslySetInnerHTML={{ __html: item?.data?.detail }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleNews;
