import React, { useEffect, useState } from "react";
import "./listingDetail.css";
import { Link } from "react-router-dom";
import ProjectCategorySlider from "../projects/ProjectCategorySlider";
import { SwiperSlide } from "swiper/react";
import { Rating, Button } from "@mui/material";
import convertToWords from "../../utils/convertAmount";
import { LuAreaChart } from "react-icons/lu";
import { BiBath } from "react-icons/bi";
import { MdOutlineBed } from "react-icons/md";
import { TbToolsKitchen3 } from "react-icons/tb";
import ProjectAmmenties from "../projects/ProjectAmmenties";
import YouTubeEmbed from "../projects/YoutubeEmbed";
import MapIframe from "../projects/MapIfram";
import ReviewList from "../reviews/ReviewList";
import ListingList from "../listings/ListingList";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import useScrollTop from "../../hooks/useScrollTop";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
const ListingDetail = () => {
  const { dispatch, navigate } = useProvideGeneralHooks();
  const [item, setItem] = useState();

  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");

  useEffect(() => {
    const getData = async () => {
      const data = {
        url: apis().getSingleListing + "?listingId=" + id,
      };
      const result = await dispatch(httpActions(data));
      if (result?.status) {
        setItem(result?.item);
      }
    };
    getData();
  }, [id]);

  useScrollTop();

  // Function to handle the WhatsApp message
  const handleWhatsAppClick = () => {
    const link = "http://localhost:3000/listing/detail?id=" + item?._id;
    const message = `Hi, I am interested in the property. Please share more details. You can find more information here: ${link}`;

    // Format the message to be URL-encoded
    const encodedMessage = encodeURIComponent(message);
    const formatednum = item?.user?.mobile?.replace("0", "");
    // Create the WhatsApp URL
    const whatsappUrl = `https://wa.me/+92${formatednum}?text=${encodedMessage}`;

    // Open WhatsApp (this will either open the app or the web version)
    window.open(whatsappUrl, "_blank");
  };


  
  return (
    <div className="listing_detail_main">
      <div className="listing_detail_container">
        <div className="listing_detail_galary">
          <ProjectCategorySlider show={1}>
            {item?.data?.image.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="listing_detail_galary_image">
                  <img src={item} />
                </div>
              </SwiperSlide>
            ))}
          </ProjectCategorySlider>
        </div>
        <div className="listing_detail_content_section">
          <div className="listing_detail_content">
            <p className="listing_detail_title">{item?.data?.title}</p>
            <div className="listing_detail_pricing">
              <span>Price</span>
              <p className="listing_detail_price">
                {convertToWords(item?.data?.price)}
              </p>
            </div>

            <div className="listing_detail_location">
              <span>Location:</span>
              <p>{item?.data?.address}</p>
            </div>

            <div className="listing_detail_features">
              <div className="listing_detail_feature">
                <div className="listing_detail_feature_pair">
                  <BiBath />
                  <span>BathRoom</span>
                </div>
                <span className="listing_detail_feature_count">
                  {item?.data?.bath}
                </span>
              </div>
              <div className="listing_detail_feature">
                <div className="listing_detail_feature_pair">
                  <MdOutlineBed />
                  <span>BedRoom</span>
                </div>
                <span className="listing_detail_feature_count">
                  {item?.data?.bed}
                </span>
              </div>
              <div className="listing_detail_feature">
                <div className="listing_detail_feature_pair">
                  <LuAreaChart />
                  <span>Area</span>
                </div>
                <span className="listing_detail_feature_count">
                  {item?.data?.area} {item?.data?.unit}
                </span>
              </div>
              <div className="listing_detail_feature">
                <div className="listing_detail_feature_pair">
                  <TbToolsKitchen3 />
                  <span>Kitchen</span>
                </div>
                <span className="listing_detail_feature_count">
                  {item?.data?.kitchen}
                </span>
              </div>
            </div>

            
            <div className="listing_detail_section">
              <span>Detail</span>
              <p>
                <div dangerouslySetInnerHTML={{ __html: item?.data?.detail }} />
              </p>
            </div>
            <span className="section_title">Ammenties</span>
            <div className="single_project_ammenties">
              {item?.data?.ammenities.map((item, index) => (
                <ProjectAmmenties item={item} key={index} />
              ))}
            </div>
          </div>
          <div className="listing_detail_contact">
            <div className="flex_center_between">
              <span className="small gray captlize">Rating</span>
              <Rating size="small" defaultValue={5} readOnly />
            </div>
            <div className="flex_center_between">
              <span className="small gray">Reviews</span>
              <span>55</span>
            </div>
            <div className="flex_center_between">
              <span className="small gray">Listed At</span>
              <span>{new Date(item?.createdAt).toLocaleDateString()}</span>
            </div>
            <div className="flex_center_between">
              <span className="small gray">Purpose</span>
              <span className="orange">{item?.data?.purpose}</span>
            </div>

            <div className="listing_contact_actions">
              <span className="section_title">Contact Now</span>
              <Link to={`tel:${item?.user?.mobile}`}>
                <Button
                  endIcon={<PhoneOutlinedIcon />}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  call
                </Button>
              </Link>
              <Button
                onClick={handleWhatsAppClick}
                endIcon={<WhatsAppIcon />}
                variant="outlined"
                color="success"
                fullWidth
              >
                whatsapp
              </Button>
            </div>
          </div>
        </div>
        <div className="listing_detail_visual">
          <div className="single_project_video">
            <span className="section_title">Site Video</span>
            <YouTubeEmbed videoId={item?.data?.videoUrl} />
          </div>
          <div className="project_map_location">
            <span className="section_title">Location</span>
            <MapIframe map={item?.data?.mapUrl} />
          </div>
        </div>

        <ListingList
          filter={{
            "data.type": item?.data?.type,
            "data.purpose": item?.data?.purpose,
            "data.condition": item?.data?.condition,
          }}
          title="Related"
        />
        <ReviewList
          filter={{ listing: item?._id }}
          listing={item?._id}
          show={4}
        />
      </div>
    </div>
  );
};

export default ListingDetail;
