import React, { useState } from "react";
import "./sidebar.css";
import { adminMenus } from "../../../utils/adminSidebarMenus";
import useProvideGEnaralHook from "../../../hooks/useProvideGeneralHooks";
const Sidebar = () => {
  const [selectedItem, setSelectedItem] = useState("");
  const { navigate } = useProvideGEnaralHook();
  const menuItemHandler = (item) => {
    setSelectedItem(item.title);
    navigate(item?.to);
  };

  return (
    <div className="sidebar_main">
      <div className="admin_sidebar_container">
        {adminMenus?.map((item, index) => {
          return (
            <div
              onClick={() => menuItemHandler(item)}
              className={
                selectedItem === item?.title
                  ? "menu_item_fill"
                  : "admin_sidebar_item"
              }
              key={index}
            >
              <>{item?.icon}</>
              <span>{item?.title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
