import React, { useEffect, useState } from "react";
import RichTextEditor from "../../common/RichTextEditor";
import { Button, TextField, Card, InputAdornment } from "@mui/material";
import FileUploader from "../../common/FileUploader";
import ArticleIcon from "@mui/icons-material/Article";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Formik, Form } from "formik";
import useProvideGeneralHooks from "../../../hooks/useProvideGeneralHooks";
import httpActions from "../../../utils/httpAction";
import { getErrorElement } from "../../../utils/getErrorElement";
import * as Yup from "yup";
import apis from "../../../utils/apis";
import toast from "react-hot-toast";

const AddNews = () => {
  const [value, setValue] = useState();
  const [files, setFiles] = useState([]);
  const { dispatch, navigate } = useProvideGeneralHooks();
  const initialState = {
    title: "",
    videoUrl: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().trim().required("Title is required"),
    videoUrl: Yup.string().optional(),
  });

  useEffect(() => {
    if (value) {
      getErrorElement("news_detail", "");
      return;
    }
    if (files.length > 0) {
      getErrorElement("news_image_upload", "");
    }
  }, [value, files]);

  const submitHandler = async (values) => {
    if (!value) {
      getErrorElement("news_detail", "news detail is required");
      return;
    }

    if (files.length === 0) {
      getErrorElement("news_image_upload", "image is required");
      return;
    }

    const newsData = {
      ...values,
      image: files[0],
      detail: value,
    };

    const data = {
      url: apis().addNews,
      method: "POST",
      body: { data: newsData },
    };

    const result = await dispatch(httpActions(data));
    if (result?.status) {
      toast.success(result?.message);
    }
  };

  return (
    <div>
      <Card sx={{ padding: "2rem" }}>
        <Formik
          initialValues={initialState}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
        >
          {({
            handleBlur,
            handleChange,
            touched,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form>
              <div className="row g-3">
                <div className="col-md-12">
                  <TextField
                    type="text"
                    name="title"
                    fullWidth
                    size="small"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                    label="News title"
                  />
                </div>
                {/* <div className="col-md-12">
                  <TextField
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <YouTubeIcon sx={{ color: "red" }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                    name="videoUrl" // Make sure name matches with validation and initialValues
                    type="text"
                    label="video URL optional"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    value={values.videoUrl}
                  />
                </div> */}
                <div className="col-md-6">
                  <p>News detail</p>
                  <span id="news_detail" className="error_message"></span>
                  <RichTextEditor detail={value} setDetail={setValue} />
                </div>
                <div className="col-md-6">
                  <p>Add any image </p>
                  <span id="news_image_upload" className="error_message"></span>
                  <FileUploader count={1} files={files} setFiles={setFiles} />
                </div>
                <div>
                  <Button
                    type="submit"
                    endIcon={<ArticleIcon />}
                    variant="contained"
                    color="secondary"
                  >
                    post news
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default AddNews;
