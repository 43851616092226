import React, {  useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { headerMenus } from "../../utils/headerMenus";
import { IoMenu } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import smallLogo from "../../assets/logo small svg.svg";
import logoHeaderWhite from "../../assets/logo header white.png";
import Clock from "react-live-clock";
const Header = () => {
  const { navigate } = useProvideGeneralHooks();

  const navigateHandler = (event, to) => {
    event.preventDefault();
    setOpenDraw(false);
    navigate(to);
  };

  const [openDraw, setOpenDraw] = useState(false);
  return (
    <div className="header_main">
      <div
        className={
          openDraw ? "mobile_header_menus_open" : "mobile_header_menus_close"
        }
      >
        <div className="mobile_header_header">
          <div className="small_logo_header">
            <img src={smallLogo} />
          </div>
          <IoCloseSharp
            onClick={() => setOpenDraw(false)}
            className="header_menu_icon"
          />
        </div>
        <div className="mobile_header_links">
          {headerMenus.map((item, index) => {
            return (
              <Link
                onClick={(event) => navigateHandler(event, item?.to)}
                key={index}
              >
                {item.title}
              </Link>
            );
          })}
        </div>
      </div>
      <div className="header_mobile">
        <div className="mobile_header_items">
          <div className="small_logo_header">
            <img src={smallLogo} />
          </div>
          <Clock format={"HH:mm:ss"} ticking={true} />
          <IoMenu
            onClick={() => setOpenDraw(true)}
            className="header_menu_icon"
          />
        </div>
      </div>
      <div className="header_container">
        <div className="header_logo">
          <Link to="/">
            <img src={logoHeaderWhite} />
          </Link>
        </div>
        <div className="header_menus flex_row  ">
          {headerMenus.map((item, index) => {
            return (
              <Link to={item.to} key={index}>
                {item.title}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Header;
