import React from "react";
import Slider from "../components/Home/Slider";
import ProjectsList from "../components/projects/ProjectsList";
import ComingSoon from "../components/Home/banners/ComingSoon";
import ListingList from "../components/listings/ListingList";
import AgentList from "../components/agents/AgentList";
import BranchList from "../components/branches/BranchList";
import ReviewList from "../components/reviews/ReviewList";
const Home = () => {
  const newHouseSaleFilter = {
    "data.type": "house",
    "data.purpose": "sale",
    "data.condition": "new",
  };
  const usedHouseSaleFilter = {
    "data.type": "house",
    "data.purpose": "sale",
    "data.condition": "used",
  };
  const usedHouseRentFilter = {
    "data.type": "house",
    "data.condition": "used",
    "data.purpose": "rent",
  };

  return (
    <div>
      <Slider />
      <ProjectsList />
      <ComingSoon />
      <ListingList filter={newHouseSaleFilter} title={"new House for sale"} />
      <ListingList filter={usedHouseSaleFilter} title="used house for sale" />
      <ListingList filter={usedHouseRentFilter} title="used house for rent" />
      <AgentList />
      <BranchList />
      <ReviewList />
    </div>
  );
};

export default Home;
