import React, { useEffect, useState } from "react";
import "./addBranch.css";
import { TextField, Button, Card, InputAdornment } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import FileUpload from "../common/FileUploader";
import { getErrorElement } from "../../utils/getErrorElement";
import apis from "../../utils/apis";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import httpActions from "../../utils/httpAction";
import toast from "react-hot-toast";
const AddBranch = () => {
  const [files, setFiles] = useState([]);
  const [profile, setProfile] = useState([]);
  const { dispatch, navigate } = useProvideGeneralHooks();
  const initialState = {
    title: "",
    address: "",
    mapUrl: "",
    videoUrl: "",
    ceo: "",
    image: "",
    ceoProfile: "",
    established: "", // Should be a string in YYYY-MM-DD format
    mobile: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().trim().required("Title is required"),
    address: Yup.string().trim().required("Address is required"),
    mapUrl: Yup.string().trim().required("Map URL is required"),
    videoUrl: Yup.string().trim().required("Video URL is required"),
    ceo: Yup.string().trim().required("CEO name is required"),
    established: Yup.date().required("Established date is required"), // Ensure it's a valid date
    mobile: Yup.string()
      .trim()
      .required("Mobile number is required")
      .test("len", "Mobile number must be exactly 11 digits", (value) => {
        return value && value.toString().length === 11;
      }),
  });
  useEffect(() => {
    if (files.length > 0) {
      getErrorElement("branch_image_upload", "");
    }
    if (profile.length > 0) {
      getErrorElement("ceo_image_upload", "");
    }
  }, [files, profile]);
  const submitHandler = async (values) => {
    if (files.length === 0) {
      getErrorElement("branch_image_upload", "branch image is required");
      return;
    }
    if (profile.length === 0) {
      getErrorElement("ceo_image_upload", "CEO image is required");
      return;
    }

    const data = {
      ...values,
      ceoProfile: profile[0],
      image: files[0],
    };
    const branchData = {
      url: apis().addBranch,
      method: "POST",
      body: { data: data },
    };

    const result = await dispatch(httpActions(branchData));
    if (result?.status) {
      toast.success(result?.message);
    }
  };

  return (
    <div className="add_branch_main">
      <Card sx={{ padding: "2rem" }}>
        <Formik
          initialValues={initialState}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({
            handleBlur,
            handleChange,
            touched,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form>
              <div className="row g-3">
                <div className="col-md-4">
                  <TextField
                    label="Branch title"
                    fullWidth
                    size="small"
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={touched.title && Boolean(errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </div>
                <div className="col-md-8">
                  <TextField
                    label="Branch address"
                    fullWidth
                    size="small"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    fullWidth
                    label="Branch mobile no"
                    size="small"
                    name="mobile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobile}
                    error={touched.mobile && Boolean(errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    fullWidth
                    label="Established Date"
                    size="small"
                    name="established"
                    type="date" // Ensuring type=date
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.established} // Value in YYYY-MM-DD format
                    error={touched.established && Boolean(errors.established)}
                    helperText={touched.established && errors.established}
                    InputLabelProps={{
                      shrink: true, // Makes the label float properly when the field is focused
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <TextField
                    name="ceo"
                    type="text"
                    fullWidth
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ceo}
                    error={touched.ceo && Boolean(errors.ceo)}
                    helperText={touched.ceo && errors.ceo}
                    label="Branch CEO name"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <YouTubeIcon sx={{ color: "red" }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                    name="videoUrl" // Make sure name matches with validation and initialValues
                    type="text"
                    label="video URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.videoUrl}
                    error={touched.videoUrl && Boolean(errors.videoUrl)}
                    helperText={touched.videoUrl && errors.videoUrl}
                    fullWidth
                    size="small"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <LocationOnIcon sx={{ color: "#4A89F3" }} />
                          </InputAdornment>
                        ),
                      },
                    }}
                    name="mapUrl"
                    value={values.mapUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.mapUrl && Boolean(errors.mapUrl)}
                    helperText={touched.mapUrl && errors.mapUrl}
                    label="map location URL"
                    fullWidth
                    size="small"
                  />
                </div>
                <div className="col-md-6">
                  <h5>Upload Branch Elevation</h5>
                  <span
                    className="error_message"
                    id="branch_image_upload"
                  ></span>
                  <FileUpload files={files} setFiles={setFiles} count={1} />
                </div>
                <div className="col-md-6">
                  <h5>Upload CEO Profile</h5>
                  <span className="error_message" id="ceo_image_upload"></span>
                  <FileUpload count={1} files={profile} setFiles={setProfile} />
                </div>
              </div>
              <Button
                type="submit"
                variant="contained"
                sx={{ marginTop: "1rem" }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default AddBranch;
