import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importing the default theme styles

const RichTextEditor = ({ detail, setDetail }) => {
  // Define a custom toolbar for the editor (optional)

    const modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ list: "ordered" }],
        ["bold", "italic", "underline"],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["link"], // Add the "link" option to the toolbar
      ],
    };
    

  return (
    <div>
      <ReactQuill
        theme="snow" // Snow theme is the default
        value={detail} // The editor value controlled by state
        onChange={setDetail} // On editor content change, update state
        modules={modules} // Optional: Custom toolbar
        placeholder="Write something..." // Optional: A placeholder text
      />
    </div>
  );
};

export default RichTextEditor;
