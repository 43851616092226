import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const useProvideGeneralHooks = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket.socket);
  
  const navigate = useNavigate();
  return {
    navigate,
    dispatch,
    socket,
  };
};

export default useProvideGeneralHooks;
