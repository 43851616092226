import React from "react";
import "./singleBranchItem.css";
import avatar from "../../../assets/ceo.jpg";
import { Button } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IoLocationSharp } from "react-icons/io5";
import MapIframe from "../../projects/MapIfram";
import YouTubeEmbed from "../../projects/YoutubeEmbed";
import SingleAgent from "../../agents/SingleAgent";
import { SwiperSlide } from "swiper/react";
import ProjectCategorySlider from "../../projects/ProjectCategorySlider";
import { SiAuth0 } from "react-icons/si";
import { Link } from "react-router-dom";
import getWidth from "../../../utils/getWidth";

const SingleBranchItem = ({ item }) => {
  return (
    <div className="single_branch_page_main">
      <div className="single_branch_page_item">
        <div className="single_branch_page_image">
          <img src={item?.ceoProfile} />
        </div>
        <div className="single_branch_page_content">
          <span className="section_title">{item?.title}</span>
          <div className="single_branch_feature_item">
            <span>CEO</span>
            <p className="captlize orange">{item?.ceo}</p>
          </div>
          <div className="single_branch_feature_item">
            <span>Established</span>
            <p>{new Date(item.established).getFullYear()}</p>
          </div>
          <div className="single_branch_feature_item">
            <span>
              <IoLocationSharp />
            </span>
            <p className="gray">{item?.address}</p>
          </div>
          <div className="sine_branch_page_auth">
            <span>Auhtorized Partner</span>
            <SiAuth0 />
          </div>
          <div className="single_branch_page_action">
            <Link style={{ width: "100%" }} to={`tel:${item?.mobile}`}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                endIcon={<CallIcon />}
              >
                Call
              </Button>
            </Link>
            <Link
              style={{ width: "100%" }}
              to={`https://wa.me/+92${item?.mobile?.replace("0", "")}`}
            >
              <Button
                variant="outlined"
                color="success"
                fullWidth
                endIcon={<WhatsAppIcon />}
              >
                Whatsapp
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="single_branch_page_item">
        <MapIframe map={item?.mapUrl} />
        <YouTubeEmbed videoId={item?.videoUrl} />
      </div>
      <div>
        <span className="section_title">Branch Staff</span>
        <ProjectCategorySlider show={getWidth() ? 1 : 3}>
          {item?.staff.map((item, index) => (
            <SwiperSlide key={index}>
              <SingleAgent agent={item} />
            </SwiperSlide>
          ))}
        </ProjectCategorySlider>
      </div>
    </div>
  );
};

export default SingleBranchItem;
