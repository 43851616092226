import React from "react";
import "./singleListing.css";
import convertToWords from "../../utils/convertAmount";
import { MdOutlineLocationOn } from "react-icons/md";
import { LuAreaChart } from "react-icons/lu";
import { MdOutlineBed } from "react-icons/md";
import { BiBath } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import useScrollTop from "../../hooks/useScrollTop";
const SingleListing = ({ item }) => {
  const navigate = useNavigate();

  const navigateToDetail = () => {
    const path = window.location.pathname;
    if (path.includes("/listing/detail")) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    navigate("/listing/detail?id=" + item?._id);
  };

  return (
    <div className="single_listing_main">
      <div className="single_listing_container">
        <div className="single_listing_image">
          {/* <div className="single_listing_overlay">

          </div> */}
          <img src={item?.data?.image[0]} />
        </div>
        <div className="single_listing_content">
          <span className="small captlize">{item?.data?.title}</span>
          <span className="orange">{convertToWords(item?.data?.price)}</span>
          <div className="flex_row">
            <MdOutlineLocationOn className="gray" />
            <span className="small captlize">{item?.data?.address}</span>
          </div>
          <div className="single_listing_features flex_center_between">
            <div className="single_listing_feature_item">
              <LuAreaChart />
              <span>
                {item?.data?.area} {item?.data?.unit}
              </span>
            </div>
            <div className="single_listing_feature_item">
              <MdOutlineBed />
              <span>{item?.data?.bed}</span>
            </div>
            <div className="single_listing_feature_item">
              <BiBath />
              <span>{item?.data?.bath}</span>
            </div>
          </div>
          <div>
            <Button
              onClick={navigateToDetail}
              fullWidth
              variant="outlined"
              color="primary"
            >
              Detail
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleListing;
