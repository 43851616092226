import React from "react";
import "./singleBranch.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";

const SingleBranch = ({ item }) => {
  const moveToMapHandler = () => {
    window.open(item?.mapUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="single_branch_main">
      <div className="single_branch_image">
        <div className="single_branch_overlay">
          <span>{item?.title}</span>
        </div>
        <img src={item?.image} />
      </div>
      <div className="single_branch_content">
        <Button
          onClick={moveToMapHandler}
          endIcon={<NearMeOutlinedIcon />}
          variant="outlined"
          color="primary"
        >
          view on map
        </Button>
        <Link style={{ width: "100%" }} to={`tel:${item?.mobile}`}>
          <Button
            fullWidth
            endIcon={<AddIcCallOutlinedIcon />}
            variant="outlined"
            color="success"
          >
            Call
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SingleBranch;
