import banner1 from "../assets/banner1.jpg";
import banner2 from "../assets/banner2.jpg";
import banner3 from "../assets/banner3.jpg";
import banner4 from "../assets/banner4.jpg";

import building1 from "../assets/building.png";
import building2 from "../assets/building2.png";
import building3 from "../assets/building3.png";

export const buildingImage = [building1, building2, building3];

export const bannerImages = [banner1, banner2, banner3, banner4];
