import React, { useEffect, useState } from "react";
import "./allListings.css";
import SingleListing from "./SingleListing";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";

import {
  TextField,
  Autocomplete,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import PagePagination from "../common/PagePagination";
import { IoKey } from "react-icons/io5";
import { FaFilePen } from "react-icons/fa6";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { FaHouse } from "react-icons/fa6";
import { AiFillShop } from "react-icons/ai";
import { FaHouseFloodWater } from "react-icons/fa6";
import { BsFillHouseDownFill } from "react-icons/bs";
import { BsFillHouseUpFill } from "react-icons/bs";

const AllListings = () => {
  const { dispatch, navigate } = useProvideGeneralHooks();
  const [listing, setListing] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filters, setFilters] = useState({
    type: "",
    purpose: "",
    max_price: null,
    min_price: null,
    location: "",
  });

  const getlistingList = async (filter = {}) => {
    const data = {
      url: apis().getListings,
      method: "POST",
      body: { filter: filter },
    };
    const result = await dispatch(httpActions(data));
    if (result?.status) {
      setListing(result?.list);
    }
  };

  useEffect(() => {
    getlistingList();
  }, []);

  const types = [
    { title: "house", icon: <FaHouse /> },
    { title: "shop", icon: <AiFillShop /> },
    { title: "plot", icon: <FaHouseFloodWater /> },
    { title: "upper portion", icon: <BsFillHouseUpFill /> },
    { title: "lower portion", icon: <BsFillHouseDownFill /> },
  ];

  const purpose = [
    { title: "rent", icon: <IoKey /> },
    { title: "buy", icon: <FaFilePen /> },
  ];

  useEffect(() => {
    const getData = async () => {
      const list = await getBranchDropdown(apis, dispatch);
      setBranches(list);
    };
    getData();
  }, []);

  const filterClickHandler = async (value, type) => {
    setFilters((prevFilter) => ({ ...prevFilter, [type]: value }));
  };

  const filterChnage = (value, type) => {
    setFilters((prevFilter) => ({ ...prevFilter, [type]: value }));
  };

  const searchHandler = async () => {
    const search = listingFilter(
      filters.type,
      filters.purpose,
      filters.min_price,
      filters.max_price,
      filters.location
    );
    await getlistingList(search);
  };

  return (
    <div className="all_listing_main">
      <div className="listing_banner">
        <div className="listin_banner_overlay">
          <p>Explore Our Exclusive Property Listings</p>
          <p>
            Find your perfect home or investment opportunity from our wide range
            of properties, including apartments, houses, and commercial spaces.
            With prime locations, modern amenities, and flexible payment plans,
            we cater to all your real estate needs.
          </p>
        </div>
      </div>

      <div className="all_listing_container">
        <Accordion sx={{ marginBottom: "1rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Apply Filter
          </AccordionSummary>
          <AccordionDetails>
            <div className="all_listing_filters">
              <div>
                <span className="section_title small ">Property Purpose</span>
                <div className="listing_filter_purpose">
                  {purpose.map((item, index) => {
                    return (
                      <div
                        className={
                          filters.purpose === item.title ? "filter_fill" : ""
                        }
                        onClick={() =>
                          filterClickHandler(item.title, "purpose")
                        }
                        key={index}
                      >
                        <>{item?.icon}</>
                        <span>{item?.title}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <span className="section_title small">
                  Select Budget & location
                </span>
                <div className="all-listing_inputs">
                  <TextField
                    onChange={(event) =>
                      filterChnage(event.target.value, "min_price")
                    }
                    fullWidth
                    label="Min Price"
                    type="number"
                    size="small"
                  />

                  <TextField
                    fullWidth
                    onChange={(event) =>
                      filterChnage(event.target.value, "max_price")
                    }
                    label="Max Price"
                    type="number"
                    size="small"
                  />

                  <Autocomplete
                    fullWidth
                    size="small"
                    value={filters.location}
                    onChange={(event, newValue) => {
                      filterChnage(newValue, "location");
                    }}
                    options={branches}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Location" />
                    )}
                  />
                </div>
              </div>

              <div>
                <span className="section_title small">
                  Select Property Type
                </span>
                <div className="all_listings_filter_types">
                  {types.map((item, index) => {
                    return (
                      <div
                        onClick={() => filterClickHandler(item.title, "type")}
                        key={index}
                        className={` ${
                          filters.type === item.title
                            ? "filter_fill"
                            : "listig_filter_type_item"
                        }`}
                      >
                        <>{item?.icon}</>
                        <span>{item?.title}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="listing_filter_actions">
                <Button
                  sx={{ fontSize: "10px" }}
                  fullWidth
                  onClick={searchHandler}
                  endIcon={<SearchIcon />}
                  variant="contained"
                  color="primary"
                >
                  search
                </Button>
                <Button
                  sx={{ fontSize: "10px" }}
                  fullWidth
                  endIcon={<CloseIcon />}
                  variant="outlined"
                  color="primary"
                >
                  clear filter
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <div className="all_listing_list">
          {listing.map((item, index) => (
            <SingleListing key={index} item={item} />
          ))}
        </div>
        <PagePagination count={10} />
      </div>
    </div>
  );
};

export default AllListings;

export function listingFilter(
  type = "",
  purpose = "",
  min_price = "",
  max_price = "",
  location = ""
) {
  const filter = {
    "data.type": type,
    "data.purpose": purpose,
    "data.price": {
      $gte: parseFloat(min_price),
      $lte: parseFloat(max_price),
    },
    // "data.branch": location,
  };
  return filter;
}

export async function getBranchDropdown(apis, dispatch) {
  const data = {
    url: apis().branchDropdown,
  };

  const result = await dispatch(httpActions(data));

  if (result?.status) {
    const formatedList = result?.list?.map((item) => item.title);
    return formatedList;
  }
}
