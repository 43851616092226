const apis = () => {
  const local = "http://localhost:8000/";
  const live = "https://portal.hafizbrothersestate.com/";
  const list = {
    uploadImage: `${live}image/upload`,
    addProject: `${live}project/add`,
    getProjectDropdown: `${live}dropdown/project`,
    addProjectCategory: `${live}category/add`,
    addAmmenity: `${live}ammenity/add`,
    ammenityList: `${live}ammenity/list`,
    getProjectList: `${live}project/list`,
    addNewUser: `${live}user/add`,
    getUserDropdown: `${live}dropdown/user`,
    userList: `${live}user/list`,
    addListing: `${live}listing/add`,
    getListings: `${live}listing/list`,
    getSingleListing: `${live}listing/single`,
    addReview: `${live}review/add`,
    getReview: `${live}review/list`,
    addBranch: `${live}branch/add`,
    branchDropdown: `${live}dropdown/branch`,
    getBranchList: `${live}branch/list`,
    addNews: `${live}news/add`,
    getNews: `${live}news/get`,
    AdminId: "126005",
  };

  return list;
};

export default apis;
