import React, { useEffect, useState } from "react";
import "./branchPageList.css";
import SingleBranchItem from "./SingleBranchItem";
import httpActions from "../../../utils/httpAction";
import apis from "../../../utils/apis";
import useProvideGeneralHooks from "../../../hooks/useProvideGeneralHooks";
const BranchPagesList = () => {
  const { dispatch, navigate } = useProvideGeneralHooks();
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getBrnachData(dispatch, apis);
      setBranches(result?.list);
    };
    fetchData();
  }, []);

  return (
    <div className="branch_page_main">
      <div className="branch_page_banner">
        <div className="branch_page_overlay">
          <p>Our Expansive Network of Branches</p>
          <p>
            Discover our widespread presence across multiple locations,
            delivering unmatched real estate services and personalized
            experiences at each branch. Explore a branch near you!
          </p>
        </div>
      </div>
      <div className="branch_page_main_section">
        <div>
          {branches?.map((item, index) => (
            <SingleBranchItem item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BranchPagesList;

export async function getBrnachData(dispatch, apis) {
  const data = {
    url: apis().getBranchList,
  };

  const result = await dispatch(httpActions(data));
  if (result?.status) {
    return result;
  }
}
