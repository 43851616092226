import React, { useEffect, useState } from "react";
import httpActions from "../../utils/httpAction";
import apis from "../../utils/apis";
import useProvideGeneralHooks from "../../hooks/useProvideGeneralHooks";
import SingleNews from "./SingleNews";
import "./news.css";
import PagePagination from "../common/PagePagination";
const News = () => {
  const { dispatch, navigate } = useProvideGeneralHooks();
  const [news, setNews] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      const data = {
        url: apis().getNews,
      };
      const result = await dispatch(httpActions(data));
      if (result?.status) {
        setNews(result?.list);
      }
    };
    fetchNews();
  }, []);

  return (
    <>
      <div className="news_header">
        <div className="news_overlay">
          <p>Latest Real Estate News</p>
          <p>
            Stay ahead of the curve with the latest updates, trends, and
            insights in the real estate market. Discover expert tips, property
            news, and more to help you make informed decisions.
          </p>
        </div>
      </div>

      <div className="news_main">
        <div>
          {news?.map((item, index) => (
            <SingleNews item={item} key={index} />
          ))}
        </div>
        <PagePagination count={10} />
      </div>
    </>
  );
};

export default News;
